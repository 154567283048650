import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useInvoiceList() {
  // Use toast
  const toast = useToast();

  const refInvoiceListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: 'reference',
      label: 'رقم الفاتورة',
      formatter: title,
      sortable: true,
    },
    {
      key: 'total',
      label: 'المبلغ',
      formatter: title,
      sortable: true,
    },
    {
      key: 'starts_at',
      label: 'الفترة من',
      formatter: title,
      sortable: true,
    },
    {
      key: 'ends_at',
      label: 'الفترة إلى',
      formatter: title,
      sortable: true,
    },
    {
      key: 'payment_status',
      label: 'حالة السداد',
      formatter: title,
      sortable: true,
    },
    {
      key: 'payment_method',
      label: 'قناة السداد',
      formatter: title,
      sortable: true,
    },
    {
      key: 'paid_at',
      label: 'تاريخ السداد',
      formatter: title,
      sortable: true,
    },
    {
      key: 'transaction_number',
      label: 'رقم معاملة السداد',
      formatter: title,
      sortable: true,
    },
    { key: 'actions', label: 'الإجراءات' },
  ];
  const perPage = ref(10);
  const totalInvoice = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const payment_statusFilter = ref(null);
  const payment_methodFilter = ref(null);
  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoice.value,
    };
  });

  const refetchData = () => {
    refInvoiceListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, payment_statusFilter, payment_methodFilter], () => {
    refetchData();
  });

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('invoice/fetchInvoices', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        payment_status: payment_statusFilter.value,
        payment_method: payment_methodFilter.value,
      })
      .then((response) => {
        console.log(response.data);
        const { data: invoices, total } = response.data;

        callback(invoices);
        totalInvoice.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدث خطأ عند جلب القائمة',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoice,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    refetchData,

    // Extra Filters
    payment_statusFilter,
    payment_methodFilter,
  };
}
