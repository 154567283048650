<template>
  <b-card
    v-if="subscription"
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        الإشتراك الحالي
      </h5>
      <b-badge variant="light-primary">
        {{ $t(`plan_${subscription.subscription.name}` ) }}
      </b-badge>

      <small class="text-muted w-100">{{ moment(subscription.expired_at).format('DD/MM/YYYY') }}</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">باقة الإشتراك: <b-badge variant="light-primary">
            {{ $t(subscription.type) }}
          </b-badge></span>
        </li>
        <li>
          <span class="align-middle">رسوم الإشتراك: {{ subscription.price }} ريال سعودي</span>
        </li>
        <li class="my-25">
          <span class="align-middle">تاريخ الإشتراك: {{ moment(subscription.expired_at).format('DD/MM/YYYY') }}</span>
        </li>
        <li>
          <span class="align-middle">عدد المستخدمين: 100</span>
        </li>
        <li>
          <span class="align-middle"> حالة الإشتراك: <b-badge :variant="subscription.is_active? 'light-primary':'light-danger'">
            {{ subscription.is_active? 'نشط':'غير نشط' }}
          </b-badge></span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import * as moment from 'moment';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  data() {
    return {
      subscription: {},
    };
  },
  created() {
    this.$http.get('api/company-subscription').then((res) => { this.subscription = res.data.data; });
  },
  methods: {
    moment() {
      return moment();
    },
  },
};
</script>

<style>

</style>
